import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import Rating from '@/assets/icn/rating.svg';
import AppstoreCta from '@/components/AppstoreCta';
import GooglePlayCta from '@/components/GooglePlayCta';

const AppSection = ({ seeMoreLink = false }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      screens: file(relativePath: { eq: "app-screenshots/5-screens.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <div className="grey-section">
      <GatsbyImage
        image={getImage(data.screens)}
        alt={t(`appPreview`)}
        className="!absolute w-[45%] right-[20px] top-[-80px] lg:!block !hidden"
      />
      <div className="xl:w-inner mx-auto text-[18px] px-6">
        <h2 className="xl:max-w-[400px] mb-6">
          {t(`appPresentationTitle-1`)}
          <span className="text-red">{t(`appPresentationTitle-2`)}</span>
          {t(`appPresentationTitle-3`)}
        </h2>
        <Rating />
        <p className="font-[300] mt-2 font-system">
          {t(`appPresentationRating`)}
        </p>
        <strong>{t(`appPresentationFree`)}</strong>
        <div className="flex flex-col items-start sm:items-center gap-2 mt-12 mb-8 text-center xl:text-left">
          <AppstoreCta />
          <GooglePlayCta />
        </div>
        {seeMoreLink && (
          <>
            <Link className="font-bold underline" to="/application">
              {t(`appPresentationLearnMore`)}
            </Link>
            {` >`}
          </>
        )}
      </div>
    </div>
  );
};

export default AppSection;
