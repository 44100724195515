import React from 'react';
import GooglePlay from '@/assets/stores-dl-cta/google-play.svg';

const GooglePlayCta = (): JSX.Element => (
  <a
    className="block"
    href="https://play.google.com/store/apps/details?id=app.lafraise&source=lf-website"
  >
    <GooglePlay width="210" />
  </a>
);

export default GooglePlayCta;
