import React from 'react';
import AppStore from '@/assets/stores-dl-cta/app-store.svg';

const AppstoreCta = (): JSX.Element => (
  <a
    className="block"
    href="https://apps.apple.com/app/apple-store/id1492431752?pt=123002829&ct=lf-website&mt=8"
  >
    <AppStore width="210" />
  </a>
);

export default AppstoreCta;
